import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import { Helmet } from "react-helmet-async";
import { defineMessages, injectIntl } from "react-intl";

import LoadingArea from "theme/components/molecules/LoadingArea";
import graphqlWithPreload from "web/core/apollo/graphqlWithPreload";
import HomeQuery from "./HomeQuery.gql";
import HomeSliceZone from "theme/modules/Prismic/Slices";
import NotFound from "theme/pages/NotFound";

const messages = defineMessages({
  pageName: {
    id: "pages.Seo.Homepage.pageName",
    defaultMessage: "Home",
  },
  metaTitle: {
    id: "pages.Seo.metaTitle",
    defaultMessage: "Jérôme Dreyfuss - Official Store",
  },
});

const Home = ({ data, intl }) => {
  const { loading, homepage } = data;

  return loading ? (
    <LoadingArea></LoadingArea>
  ) : typeof homepage?.slices == "undefined" ? (
    <NotFound />
  ) : (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(messages.pageName)} {`|`}{" "}
          {intl.formatMessage(messages.metaTitle)}
        </title>
      </Helmet>
      <HomeSliceZone content={homepage.slices} />
    </>
  );
};

export default compose(
  graphqlWithPreload(HomeQuery),
  withRouter,
  withTrackPage("Home"),
  injectIntl
)(Home);
